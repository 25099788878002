<template>
  <div>
    <b
      class="mt-1"
      v-html="word.word"
    />
    <feather-icon
      class="ml-1 text-danger mt-1 word-remove"
      icon="TrashIcon"
      size="14"
      @click="removeWords(k,i,word.id)"
    />
    <div class="select-btn">
      <b-form-checkbox style="zoom: 1.2;"
                       @change="v => $emit('onImageSelected', v)"
      />
    </div>
    <input
      :ref="`image-uploader-${k}-${i}`"
      type="file"
      style="height:0px;width:0px;opacity:0px"
      @input="imageSelected($event,k,i,word.id)"
    >
    <b-button
      :class="word.image && 'p-0'"
      size="sm"
      class="ml-1 mt-1"
      @click="selectImage(k,i)"
    >
      <b-img
        v-if="word.image"
        :src="word.image"
        class="p-0"
        thumbnail
        style="height: 50px;width: 50px"
      />

      <feather-icon
        v-else
        icon="UploadIcon"
        class="mr-50"
      />

    </b-button>
    <div
      class="mb-1 mt-1 mr-1 image-generate"
    >
      <b-button
        variant="success"
        size="sm"
        @click="showGenerateImage = true"
      >
        <feather-icon
          icon="RefreshCcwIcon"
        />
      </b-button>
      <b-button
        variant="primary"
        size="sm"
        class="ml-2"
        @click="showImageLibrary = true"
      >
        <feather-icon
          icon="ImageIcon"
        />
      </b-button>
      <!-- <b-button
        variant="primary"
        size="sm"
        class="ml-2"
        @click="wordToGenerateImage = true"
      >
        <feather-icon
          icon="SaveIcon"
        />
      </b-button> -->
    </div>
    <Recorder
      :key="word.audio"
      class="mt-1"
      :url="word.audio"
      @onRemove="word.audio = ''"
      @input="setWordSelectorAudio(word, $event)"
      @onSelectedFromLibrary="setAudioFromLibrary"
    />
    {{ $t('curriculum-pages-module.incorrect-word') }}
    <b-form-input
      v-model="word.incorrect_words"
      placeholder="incorrect words (word1,word2,..)"
      class="mb-1"
      @change="updateIncorrectWordsForParagraph(word)"
    />
    <WordImageGenerator v-if="showGenerateImage"
                        :is-processing="isSaving"
                        :word="word.word"
                        :source-from="`cb`"
                        @close="showGenerateImage = false"
                        @pickImage="handlePickFromImageGenerator"
    />
    <ImageLibraryPicker :show="showImageLibrary"
                        @close="showImageLibrary = false"
                        @pickImage="handlePickFromLibrary"
    />
    <DeleteModal title="Generate?"
                 :show="!!wordToGenerateImage"
                 sub-title="Are you sure you want generate word?"
                 :on-delete="handleGenerate"
                 :is-processing-delete="isProcessing"
                 varient="primary"
                 @close="wordToGenerateImage = null"
    />
  </div>
</template>

<script>
import Recorder from '@/views/super/problem/components/recorder.vue'
import {
  BButton, BImg, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import WordImageGenerator from '@/views/super/ai-ide/stable-diffusion/WordImageGenerator.vue'
import ImageLibraryPicker from '@/views/common/components/ImageLibraryPick.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    Recorder,
    BButton,
    BImg,
    BFormInput,
    BFormCheckbox,
    DeleteModal,
    WordImageGenerator,
    ImageLibraryPicker,
  },
  props: ['word', 'removeWords', 'imageSelected', 'setWordSelectorAudio', 'updateIncorrectWordsForParagraph', 'k', 'i'],
  data() {
    return {
      showGenerateImage: false,
      isSaving: false,
      isProcessing: false,
      showImageLibrary: false,
      wordToGenerateImage: null,
    }
  },
  methods: {
    selectImage(k, i) {
      this.$refs[`image-uploader-${k}-${i}`].click()
    },
    handlePickFromImageGenerator(image, libId) {
      this.word.image = image
      this.imageSelected(image, this.k, this.i, this.word.id, 'picked', {
        lib_id: libId,
      })
      this.showGenerateImage = false
    },
    handlePickFromLibrary(item) {
      this.word.image = item.image
      this.showImageLibrary = false
      this.imageSelected(item.image, this.k, this.i, this.word.id, 'picked', {
        lib_id: item.id,
      })
    },
    setAudioFromLibrary(value) {
      this.word.audio = value
      this.$forceUpdate()
      this.setWordSelectorAudio(this.word, { audio: value }, 'picked')
    },
    handleGenerate() {
      this.isProcessing = true
      useJwt.saveImageLibraryWordForGeneratedWord(this.word.id, {
        word: this.word.word,
      }).then(response => {
        this.wordToGenerateImage = ''
        this.showSuccessMessage(response)
        this.getImageLibrary()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
